.body {
  /* font-family: 'Times New Roman', Times, serif; */
}

.body header {
  background-color: black !important;
}

.nav-links {
  margin-left: auto;
  margin-right: 0;
}

.nav-links a {
  padding: 0 1em 0 1em;
  color: white;
  text-decoration: none;
}

.nav-links a:last-child {
  padding-left: 5rem;
  color: white;
}

.content {
  padding: 5em;
}

.footer {
  margin-top: auto;
  margin-bottom: 0;
  background-color: lightgray;
  padding: 5em;
}